import React from 'react';
import { graphql } from 'gatsby';
import mq from '@/styles/mq';
import { SEO } from '@/components/atoms';
import { Layout } from '@/components/organisms';

import AboutContainer from '@/containers/About/AboutContainer';

import { PrismicAboutPage } from '@/domain/PrismicAboutPage';
import { PageContext } from '@/domain/PageContext';


interface AboutTemplateProps {
    data: {
        prismicAproposPage: PrismicAboutPage;
    };
    pageContext: PageContext;
}

export const query = graphql`
  query ($id: String!) {
    prismicAproposPage(id: { eq: $id }) {
      id
      lang
      alternate_languages {
        lang
        uid
      }
      data {
        meta_title
        meta_description
        apropos_content {
          raw
        }
        apropos_content_2 {
          raw
        }
        apropos_content_3 {
          raw
        }
      }
    }
  }
`;

const AboutTemplate = ({ data, pageContext }: AboutTemplateProps): JSX.Element => {
    const { alternate } = pageContext;
    const { lang, uid, alternate_languages } = data?.prismicAproposPage;
    const { meta_title, meta_description } = data?.prismicAproposPage?.data;

    const selectlang = {
        lang,
        uid: uid,
        alternate_languages,
    };
    const seo = {
        title: meta_title,
        description: meta_description,
        lang: lang,
        url: `${lang}`
    }
    const linkAlternate = alternate?.map(data => ({
        lang: data?.lang,
        url: `${data?.lang}/${data?.uid}`,
    }))
    return (
        <Layout selectLang={selectlang}>
            <SEO lang={seo.lang} title={`${seo.title}`} url={seo.url} description={seo.description} alternate={linkAlternate} />
            <AboutContainer data={data} lang={lang} />
        </Layout>
    );
};

export default AboutTemplate;