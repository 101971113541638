import React from 'react';
import { RichTextBlock } from 'prismic-reactjs';
import styled from '@emotion/styled';

import { Content } from '@/components/molecules';
import PrismicComponent from '@/prismic/prismicComponent';

import mq from '@/styles/mq';

interface AboutContentProps {
    content: RichTextBlock[];
    content2: RichTextBlock[];
    content3: RichTextBlock[];
}

const AboutContentRoot = styled.section``;

const BottomContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    ${mq('md')} {
        flex-direction: row;
    }
`;

const LeftContent = styled(PrismicComponent)`
    background-color: ${({ theme }) => theme.color.primary.main};
    width: 100%;
    padding-top: ${({ theme }) => theme.spacing(1)};
    padding-left: ${({ theme }) => theme.spacing(1)};
    padding-right: ${({ theme }) => theme.spacing(1)};
    padding-bottom: ${({ theme }) => theme.spacing(3)};
    ${mq('xs')} {
        padding-top: ${({ theme }) => theme.spacing(3)};
        padding-left: ${({ theme }) => theme.spacing(3)};
        padding-right: ${({ theme }) => theme.spacing(3)};
        padding-bottom: ${({ theme }) => theme.spacing(3)};
    }
    ${mq('md')} {
        width: 50%;
        padding-top: ${({ theme }) => theme.spacing(5)};
        padding-left: ${({ theme }) => theme.spacing(5)};
        padding-right: ${({ theme }) => theme.spacing(5)};
        padding-bottom: ${({ theme }) => theme.spacing(5)};
    }
    h1, h2, h3, h4 {
        color: ${({ theme }) => theme.color.white.light};
    }
    p, span, ul, li, ol {
        color: ${({ theme }) => theme.color.white.main};
    }
`;

const RightContent = styled(PrismicComponent)`
    width: 100%;
    padding-top: ${({ theme }) => theme.spacing(1)};
    padding-left: ${({ theme }) => theme.spacing(1)};
    padding-right: ${({ theme }) => theme.spacing(1)};
    padding-bottom: ${({ theme }) => theme.spacing(1)};
    ${mq('xs')} {
        padding-top: ${({ theme }) => theme.spacing(3)};
        padding-left: ${({ theme }) => theme.spacing(3)};
        padding-right: ${({ theme }) => theme.spacing(3)};
        padding-bottom: ${({ theme }) => theme.spacing(3)};
    }
    ${mq('md')} {
        width: 50%;
        padding-top: ${({ theme }) => theme.spacing(5)};
        padding-left: ${({ theme }) => theme.spacing(5)};
        padding-right: ${({ theme }) => theme.spacing(5)};
        padding-bottom: ${({ theme }) => theme.spacing(5)};
    }
`;

const AboutContent = ({ content, content2, content3 }: AboutContentProps): JSX.Element => {


    return (
        <AboutContentRoot>
            <Content content={content} />
            <BottomContent>
                <LeftContent content={content2} />
                <RightContent content={content3} />
            </BottomContent>
        </AboutContentRoot>
    );
};

export default AboutContent;