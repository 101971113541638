import React from 'react';
import styled from '@emotion/styled';

import { Banner, ContactBox } from '@/components/molecules';
import AboutContent from '@/containers/About/AboutContent';
import { PrismicAboutPage } from '@/domain/PrismicAboutPage';

interface AboutContainerProps {
    data: {
        prismicAproposPage: PrismicAboutPage;
    };
    lang: string;
}

const AboutContainerRoot = styled.section``;

const AboutContainer = ({ data, lang }: AboutContainerProps): JSX.Element => {
    const { apropos_content, apropos_content_2, apropos_content_3 } = data?.prismicAproposPage?.data;

   const Content = {
       content: apropos_content?.raw,
       content2: apropos_content_2?.raw,
       content3: apropos_content_3?.raw,
   }

    return (
        <AboutContainerRoot>
            <Banner lang={lang} />
            <AboutContent {...Content} />
            <ContactBox lang={lang} />
        </AboutContainerRoot>
    );
};

export default AboutContainer;